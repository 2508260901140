<template>
    <router-view></router-view>
</template>

<script setup></script>

<style lang="scss">
html,
body {
    width: 100%;
    height: 100%;
}

#app {
    width: 100%;
    height: 100%;
}
</style>
